<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <v-card class="modal">
        <v-card-title class="modal-title">Documentos</v-card-title>
        <v-divider></v-divider>
        <validation-observer>
          <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <field
                    v-model="state.form.cpf"
                    type="cpf"
                    label="CPF"
                    name="cpf"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <field
                    v-model="state.form.rg"
                    type="rg"
                    label="RG"
                    name="rg"
                    :isRequired="true"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <field
                    v-model="state.form.issuingBody"
                    type="genericText"
                    label="Órgão expedidor"
                    name="issuingBody"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-space-between">
              <v-btn
                class="cancel-button black-3--text"
                color="#ffffff"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid"
                color="secondary-color-1 white--text"
                class="next-step-button"
              >
                Confirmar e Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
  import Field from "@/modules/core/views/components/Field";
  import DateField from "@/modules/core/views/components/DateField";

  export default {
    props: {
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      Field
    },
    methods: {
      submitForm() {
        this.state.dialog = false;
        this.$emit("submit", {
          ...this.state.form
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
