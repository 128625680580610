<template>
  <div>
    <v-dialog v-model="state.dialog" :persistent="true" width="488">
      <v-card class="modal">
        <v-card-title class="modal-title">Perfil de Usuário</v-card-title>
        <v-divider></v-divider>
        <validation-observer>
          <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
            <v-card-text
              ><v-row dense>
                <v-col cols="12" sm="6">
                  <field
                    v-model="state.form.roleId"
                    name="profile"
                    type="select"
                    label="Perfil"
                    :items="profiles"
                    item-text="description"
                    item-value="id"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <field
                    v-model="state.form.cnh"
                    type="cnh"
                    label="CNH"
                    name="cnh"
                    :isRequired="isRequiredSelectCnh || hasSelectedCategoryCnh"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <field
                    v-model="state.form.categoryCnh"
                    type="select"
                    :items="cnhCategories"
                    item-text="description"
                    item-value="id"
                    label="Categoria CNH"
                    name="categoryCnh"
                    :isRequired="isRequiredSelectCnh || hasSelectedCnh"
                  />
                </v-col> </v-row
            ></v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-space-between">
              <v-btn
                class="cancel-button black-3--text"
                color="#ffffff"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid"
                color="secondary-color-1 white--text"
                class="next-step-button"
                >Confirmar e Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
  import Field from "@/modules/core/views/components/Field";
  import { profiles, commonProfiles } from "@/modules/core/enums/profiles.enum";
  import { cnhCategories } from "@/modules/core/enums/cnhCategories.enum";

  import { mapGetters } from "vuex";

  export default {
    props: {
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      Field
    },
    data: () => ({
      isRequiredSelectCnh: false,
      profiles: [],
      cnhCategories: [{ description: "Nenhum", id: "" }]
    }),
    mounted() {
      this.profiles = Object.values(this.profileSelect());
      this.cnhCategories = [
        ...this.cnhCategories,
        ...this.mountCnhCategories()
      ];
    },
    watch: {
      "state.form.roleId"(roleId) {
        this.isRequiredSelectCnh = this.canSelectCnh(roleId);
      }
    },
    computed: {
      ...mapGetters("user", {
        userIsAdmin: "getUserIsAdmin"
      }),
      hasSelectedCategoryCnh() {
        return !!this.state.form.categoryCnh;
      },
      hasSelectedCnh() {
        return !!this.state.form.cnh;
      }
    },
    methods: {
      canSelectCnh(roleId) {
        return roleId === profiles.DRIVER.id;
      },
      profileSelect() {
        if (this.userIsAdmin) {
          return { ...profiles };
        }

        return { ...commonProfiles };
      },
      mountCnhCategories() {
        return Object.keys(cnhCategories).map(cnhCategory => ({
          description: cnhCategory,
          value: cnhCategory
        }));
      },
      submitForm() {
        this.state.dialog = false;
        this.$emit("submit", {
          ...this.state.form
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
