<template>
  <v-container>
    <title-header :title="$route.meta.title" />

    <v-card class="mt-3 pt-7">
      <v-layout justify-center class="fill">
        <user-photo
          v-model="uploadedPhoto"
          :uploadUrl="uploadUrl"
          @submit="submitPhoto"
          :canEdit="mode.canEdit"
        />
      </v-layout>

      <v-layout v-if="!isLoading" flex-column class="ml-5 mr-4 mt-8 pb-10">
        <section-title
          title="Dados Pessoais"
          :canEdit="mode.canEdit"
          @edit="handleEditPersonalData"
        />
        <section-content label="Nome">{{ user.fullName }}</section-content>
        <section-content label="E-mail">{{ user.email }}</section-content>
        <section-content label="Data de Nascimento">{{
          user.birthDate | formatDate
        }}</section-content>

        <section-title
          title="Documentos"
          :canEdit="mode.canEdit"
          @edit="handleEditDocuments"
        />
        <section-content label="CPF">{{
          user.cpf | VMask(masks.cpf)
        }}</section-content>
        <section-content v-if="user.rg" label="RG"
          ><span>{{ user.rg }}</span
          ><span class="ml-5">{{ user.issuingBody }}</span></section-content
        >

        <section-title
          title="Contatos"
          :canEdit="mode.canEdit"
          @edit="handleEditContacts"
        >
        </section-title>
        <section-content
          v-for="phone in user.phones"
          :key="`phone-${phone.id}`"
          :label="phone.type ? phone.type : 'Telefone'"
          >{{ phone.phoneNumber }}</section-content
        >
        <p v-if="!user.phones.length">Não informado</p>

        <section-title
          title="Perfil"
          :canEdit="mode.canEdit"
          @edit="handleEditProfile"
        />
        <section-content label="Perfil">{{ user.role }}</section-content>
        <section-content
          v-if="user.cnh && user.categoryCnh"
          label="CNH (Carteira de Habilitação Nacional)"
        >
          <span>{{ user.cnh }}</span>
          <span class="ml-5">Categoria: {{ user.categoryCnh }}</span>
        </section-content>

        <section-title v-if="user.crcs.length" title="Local" :canEdit="false" />
        <section-content
          v-for="crc in user.crcs"
          :key="`crc-${crc.id}`"
          label="CRC"
          >{{ crc.description }}</section-content
        >

        <update-personal-data :state="personalDataState" @submit="submitForm" />
        <update-documents :state="documentsState" @submit="submitForm" />
        <update-contacts :state="contactsState" @submit="submitForm" />
        <update-profile :state="profileState" @submit="submitForm" />
      </v-layout>
    </v-card>
  </v-container>
</template>
<script>
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import SectionTitle from "@/modules/account/user/views/components/SectionTitle";
  import SectionContent from "@/modules/account/user/views/components/SectionContent";
  import UpdatePersonalData from "@/modules/account/user/views/components/UpdatePersonalData";
  import UpdateDocuments from "@/modules/account/user/views/components/UpdateDocuments";
  import UpdateContacts from "@/modules/account/user/views/components/UpdateContacts";
  import UpdateProfile from "@/modules/account/user/views/components/UpdateProfile";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import MasksMixin from "@/modules/core/mixins/masksMixin";

  import { getPhoneLabelByValue } from "@/modules/core/enums/phoneTypes.enum";
  import { adminProfiles } from "@/modules/core/enums/profiles.enum";
  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  import { mapActions } from "vuex";

  export default {
    name: "UserProfile",

    components: {
      UserPhoto,
      SectionTitle,
      SectionContent,
      UpdatePersonalData,
      UpdateDocuments,
      UpdateContacts,
      UpdateProfile,
      TitleHeader
    },

    mixins: [MasksMixin],

    data: () => ({
      title: "Meu Perfil",
      uploadUrl: process.env.VUE_APP_API_BASE_URL + "/file",
      uploadedPhoto: null,
      user: {},

      modes: {
        EDIT: {
          description: "EDIT",
          canEdit: true,
          title: "Editar Perfil"
        },
        VIEW: {
          description: "VIEW",
          canEdit: false,
          title: "Visualizar Perfil"
        },
        VIEW_SELF: {
          description: "VIEW_SELF",
          canEdit: false,
          title: "Meu Perfil"
        }
      },

      personalDataState: {
        dialog: false,
        form: {
          firstName: "",
          surname: "",
          email: "",
          birthDate: ""
        }
      },

      documentsState: {
        dialog: false,
        form: {
          cpf: "",
          rg: "",
          issuingBody: ""
        }
      },

      contactsState: {
        dialog: false,
        form: {
          phones: []
        }
      },

      profileState: {
        dialog: false,
        form: {
          roleId: 0,
          cnh: "",
          categoryCnh: ""
        }
      },

      isLoading: true
    }),

    async mounted() {
      await this.initPage();
    },

    computed: {
      mode() {
        return this.getMode();
      },

      formData() {
        if (!this.user) {
          return;
        }

        return {
          id: this.user.id,
          firstName: this.user.firstName,
          surname: this.user.surname,
          cpf: this.user.cpf,
          email: this.user.email,
          birthDate: this.user.birthDate,
          phones: preparePhonesToSubmit(this.user.phones),
          userRoles: this.mountUserRoles(),
          document: {
            id: this.user.documentId,
            rg: this.user.rg,
            issuingBody: this.user.issuingBody,
            cnh: this.user.cnh,
            categoryCnh: this.user.categoryCnh
          },
          photo: this.user.photo
        };
      }
    },

    methods: {
      ...mapActions("user", ["getUser", "updateUser", "getMe"]),
      ...mapActions(["toggleSnackbar"]),

      getMode() {
        if (!this.$route.meta.mode) {
          throw new Error("UserProfile page MUST have a mode");
        }
        if (!this.modes[this.$route.meta.mode]) {
          throw new Error("Invalid mode, expected: EDIT, VIEW or VIEW_SELF");
        }
        return this.modes[this.$route.meta.mode];
      },

      async initPage() {
        await this.initUser();
        this.initUserPhoto();
      },

      async updatePage() {
        await this.initUser();
        this.initUserPhoto();
      },

      async initUser() {
        const response = await this.fetchUser();

        this.user = this.formatUserData(response.data);
        this.isLoading = false;
      },

      async fetchUser() {
        if (
          this.mode.description === this.modes.EDIT.description ||
          this.mode.description === this.modes.VIEW.description
        ) {
          return await this.getUser(this.$route.params.id);
        }
        return await this.getMe();
      },

      initUserPhoto() {
        this.uploadedPhoto = this.user.photo?.hash;
      },

      formatUserData(user) {
        const userRole = user.userRoles[0].role;
        return {
          id: user.id,
          firstName: user.firstName,
          surname: user.surname,
          fullName: `${user.firstName} ${user.surname}`,
          email: user.email,
          birthDate: user.birthDate,
          cpf: user.cpf,
          documentId: user?.document?.id ?? null,
          rg: user.document?.rg ? user.document.rg : null,
          issuingBody: user.document?.issuingBody
            ? user.document.issuingBody
            : null,
          role: userRole.description,
          roleId: userRole.id,
          cnh: user.document?.cnh ? user.document.cnh : null,
          categoryCnh: user.document?.categoryCnh
            ? user.document.categoryCnh
            : null,
          crcs: this.mountUserCrcs(user.userRoles),
          phones: this.mountPhones(user.phones),
          photo: user.photo ?? null
        };
      },

      mountUserCrcs(userRoles) {
        const crcs = [];
        userRoles.forEach(userRole => {
          if (userRole.crc && userRole.crc.id) {
            crcs.push({
              id: userRole.crc.id,
              description: userRole.crc.description
            });
          }
        });
        return crcs;
      },

      mountPhones(phones) {
        return phones.map(phone => ({
          id: phone.id,
          type: getPhoneLabelByValue(phone.phoneType),
          phoneType: phone.phoneType,
          phoneNumber: this.parsePhone(phone)
        }));
      },

      handleEditPersonalData() {
        this.personalDataState.form.firstName = this.user.firstName;
        this.personalDataState.form.surname = this.user.surname;
        this.personalDataState.form.email = this.user.email;
        this.personalDataState.form.birthDate = this.user.birthDate;
        this.personalDataState.dialog = true;
      },

      handleEditDocuments() {
        this.documentsState.form.cpf = this.user.cpf;
        this.documentsState.form.rg = this.user.rg;
        this.documentsState.form.issuingBody = this.user.issuingBody;
        this.documentsState.dialog = true;
      },

      handleEditContacts() {
        this.contactsState.form.phones = [...this.user.phones];
        this.contactsState.dialog = true;
      },

      handleEditProfile() {
        this.profileState.form.roleId = this.user.roleId;
        this.profileState.form.cnh = this.user.cnh;
        this.profileState.form.categoryCnh = this.user.categoryCnh;
        this.profileState.dialog = true;
      },

      async submitPhoto(data) {
        await this.submitForm({ photo: data });
      },

      async submitForm(formData) {
        try {
          const payload = this.mountUpdateUserPayload(formData);
          const response = await this.updateUser(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "Registro alterado com sucesso",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error.data?.message ?? "Ocorreu um erro inesperado",
            type: "error"
          });
        } finally {
          await this.updatePage();
        }
      },

      mountUserRoles() {
        const role = {
          id: this.user.roleId
        };
        const isAdmin = adminProfiles.some(profile => profile.id === role.id);

        if (!this.user.crcs?.length) {
          return [{ role }];
        }

        return this.user.crcs.map(userCrc => {
          const crc = isAdmin ? null : { id: userCrc.id };
          return { role, crc };
        });
      },

      mountUpdateUserPayload(formData) {
        this.patch(formData);
        return {
          id: this.user.id,
          data: this.formData
        };
      },

      patch(formData) {
        this.user = {
          ...this.user,
          ...formData
        };
      }
    }
  };
</script>
<style lang="scss" scoped></style>
