<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" :persistent="true" width="588">
      <v-card class="modal">
        <v-card-title class="modal-title">Contatos</v-card-title>
        <v-divider></v-divider>

        <validation-observer>
          <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
            <v-card-text>
              <phone-field v-model="state.form.phones" :isRequired="true" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-space-between">
              <v-btn
                class="cancel-button black-3--text"
                color="#ffffff"
                @click="state.dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                :disabled="invalid"
                color="secondary-color-1 white--text"
                class="next-step-button"
                >Confirmar e Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { ValidationObserver } from "vee-validate";
  import PhoneField from "@/modules/core/views/components/PhoneField";

  export default {
    props: {
      state: {
        type: Object,
        required: true
      }
    },
    components: {
      ValidationObserver,
      PhoneField
    },
    methods: {
      submitForm() {
        this.state.dialog = false;
        this.$emit("submit", {
          ...this.state.form
        });
      }
    }
  };
</script>
<style lang="scss" scoped></style>
